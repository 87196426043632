<template lang="pug">
.left-sidebar-content
  h3.title Assets
  .asset-wrap
    .group(v-for="(one, index) in arrayList", :key="index")
      img(:src="one.image")
</template>

<script setup lang="ts">
const props = defineProps(["list"]);

// TODO Remove test
const arrayList = [...props.list, ...props.list, ...props.list, ...props.list];
</script>

<style lang="scss" scoped>
.left-sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  overflow: hidden;

  .title {
    padding: 0 10px;
  }

  .asset-wrap {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 10px;
  }

  .group {
    display: flex;
    flex-direction: row;
    gap: 10px;

    img {
      width: 100%;
    }
  }
}
</style>
