<template lang="pug">
teleport(to="head title")
  | {{ appMetaTitle }}
router-view
mars-browser-detect
</template>

<script setup lang="ts">
import { useDefaultStore } from "./store";
// const defaultSiteTitle = "Mars HTML Builder";

const store = useDefaultStore();
const appMetaTitle = store.getGlobalProp("site-title")?.value;
</script>

<style lang="scss">
@import "./assets/css/base.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

* {
  $light-border: #d3d3d321;
  scrollbar-width: thin;
  scrollbar-color: $light-border #343a40;
  outline-color: $light-border;
  outline-width: 1.5px;

  &::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    // background: $light-scrollbar;
    background: #6d6e70;
  }
}

#app {
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  color: $text-color;
}

input,
textarea {
  font-family: "Poppins";
}
</style>
