<template lang="pug">
browser-detect(v-if="isOutdatedBrowser", :options="options")
</template>

<script>
import BrowserDetect from "@/components/browser-detect/BrowserDetect.vue";
export default {
  components: {
    BrowserDetect,
  },
  data() {
    return {
      isOutdatedBrowser: false,
      options: {
        wrapper: {
          style: {
            background: "rgba(0, 0, 0, 0.7)",
          },
        },
      },
    };
  },
  mounted() {
    let accentColorSupport = false;
    try {
      accentColorSupport = CSS.supports("accent-color", "green");
    } catch (err) {
      // ignored
    }
    this.isOutdatedBrowser = !Object.fromEntries || !accentColorSupport;
  },
};
</script>
