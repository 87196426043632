<template lang="pug">
.sidebar-wrap(:class="[orientation]", ref="sidebarWrap")
  aside.sidebar-comp(
    :style="dynamicWidth",
    :class="[{ 'hidden-sidebar': isSidebarHidden || !isMobileSidebarExpanded }]"
  )
    .top
    .bottom
      .content
        slot(name="comp")
          span Missing slot content
      drag-bar(
        title="Double click to hide sidebar",
        :mouse-move-prop-handler="mouseMovePropHandler",
        :remove-mouse-move-prop-handler="removeMouseMovePropHandler",
        @dblclick="onDoubleClick",
        v-if="!isMobile"
      )
  .sidebar-expand(v-if="isSidebarHidden")
    button(@click="showSidebar")
      i.fa.fa-chevron-right
  .sidebar-collapse(v-if="isMobile && !isSidebarHidden")
    button(@click="hideSidebar")
      i.fa.fa-chevron-left
</template>

<script>
import DragBar from "@/components/drag-bar/DragBar.vue";
import { useBreakpointsLogic } from "@/assets/js/globalComposables";

export default {
  name: "SidebarComp",
  components: {
    DragBar,
  },
  props: {
    sidebarID: {
      // This is used for localstorage values [instance]
      required: true,
    },
    isSidebarHidden: {
      default: false,
    },
    orientation: {
      default: "left",
    },
    isMobileSidebarExpanded: {
      default: false,
    },
  },
  emits: ["change-sidebar-width", "hide-sidebar"],
  setup() {
    const { isMobile } = useBreakpointsLogic();
    return { isMobile };
  },
  data() {
    return {
      defaultSidebarWidthNum: 300,
      sidebarMinimumWidth: 200,
      sidebarWidth: "300px",
    };
  },
  computed: {
    dynamicWidth() {
      return {
        width: this.sidebarWidth,
      };
    },
  },
  watch: {
    $route(val) {
      this.checkSesStorage();
    },
    sidebarWidth(val) {
      localStorage[this.sidebarID] = this.sidebarWidth;
    },
    isSidebarHidden(isHidden) {
      if (isHidden) {
        localStorage[this.sidebarID] = "0px";
      } else {
        localStorage[this.sidebarID] = this.sidebarWidth;
      }
    },
  },
  mounted() {
    this.checkSesStorage();
  },
  activated() {
    this.checkSesStorage();
  },
  methods: {
    checkSesStorage() {
      const sideWidth = localStorage[this.sidebarID];
      const parsedWidth = parseInt(sideWidth, 10);
      if (sideWidth && !isNaN(parsedWidth)) {
        // console.log('Importing sidebar width from session')
        if (parsedWidth < this.sidebarMinimumWidth) {
          this.hideSidebarState(true);
        } else {
          this.sidebarWidth = sideWidth;
          this.changeSidebarWidth(this.sidebarWidth);
          this.hideSidebarState(false);
        }
      }
    },
    showSidebar() {
      const parsedInt = parseInt(this.sidebarWidth, 10);
      let sidebarWidth = this.sidebarWidth;
      if (isNaN(parsedInt)) {
        console.warn("Invalid sidebar wid [NaN]", this.sidebarWidth);
        sidebarWidth = `${this.defaultSidebarWidthNum}`;
      }
      let parseWidth = sidebarWidth.match(/\d+/u)[0];
      if (this.isMobile) {
        parseWidth = 250;
      }
      this.sidebarWidth = `${parseFloat(parseWidth, 10)}px`;
      this.hideSidebarState(false);
      this.changeSidebarWidth(this.sidebarWidth);
    },
    hideSidebar() {
      this.hideSidebarState(true);
      this.changeSidebarWidth("0px");
    },
    mouseMovePropHandler(clientX) {
      const _upperLimit = window.innerWidth / 2;
      const _offset = 10;
      const sidebarElRect = this.$refs.sidebarWrap.getBoundingClientRect();
      const sidebarPosX = sidebarElRect.x;
      const sidebarPosWidth = sidebarElRect.width;
      let calcWidth = 0;
      if (this.orientation === "right") {
        calcWidth = sidebarPosX + sidebarElRect.width - clientX;
      } else {
        calcWidth = clientX - sidebarPosX;
      }

      if (isNaN(calcWidth)) {
        console.warn("Invalid calcWidth", calcWidth);
        return;
      }

      if (
        calcWidth - _offset <
        this.sidebarMinimumWidth
        // && !this.isSidebarHidden
      ) {
        this.hideSidebarState(true);
        return;
      } else if (
        ((this.orientation === "left" &&
          calcWidth > sidebarPosX + this.sidebarMinimumWidth) ||
          (this.orientation === "right" &&
            calcWidth < sidebarPosX - this.sidebarMinimumWidth)) &&
        this.isSidebarHidden
      ) {
        this.hideSidebarState(false);
      } else if (calcWidth > _upperLimit) {
        // Max-width
        return;
      }

      this.sidebarWidth = `${calcWidth}px`;
    },
    removeMouseMovePropHandler(evt) {
      if (this.isSidebarHidden) {
        this.changeSidebarWidth("0px");
      } else {
        this.changeSidebarWidth(this.sidebarWidth);
      }
    },
    onDoubleClick() {
      this.hideSidebar();
    },
    changeSidebarWidth(val) {
      this.$emit("change-sidebar-width", val, this.orientation);
    },
    hideSidebarState(isHidden = false) {
      if (this.isSidebarHidden !== isHidden) {
        this.$emit("hide-sidebar", isHidden, this.orientation);
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar-wrap {
  $collapsable-back: rgb(255, 84, 118);
  $button-wid: 19px;

  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;

  aside.sidebar-comp {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.hidden-sidebar {
      display: none;
    }

    & > .top {
      margin: 0 22px;
    }

    & > .bottom {
      display: flex;
      height: 100%;

      & > .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
      }
    }
  }

  .sidebar-expand {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;

    button {
      margin: 0;
      padding: 0 3px;
      z-index: 10;
      width: 19px;
      transition: all 0.3s ease;

      &:hover {
        width: 22px;
        transition: all 0.3s ease;
      }
    }
  }

  .sidebar-collapse {
    position: absolute;

    button {
      margin: 0;
      padding: 0 3px;
      z-index: 10;
      width: $button-wid;
      transition: all 0.3s ease;
      position: relative;
      z-index: 2;
      background: $collapsable-back;
      align-items: center;

      &:hover {
        width: 25px;
        transition: all 0.3s ease;

        // .fa {
        //   transform: rotate(180deg);
        // }
      }
    }
  }

  &.left {
    .bottom {
      flex-direction: row;
    }

    .sidebar-expand {
      right: -$button-wid;
      button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .sidebar-collapse {
      left: 0;

      button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .fa {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.right {
    .bottom {
      flex-direction: row-reverse;
    }

    .sidebar-expand {
      left: -$button-wid;

      button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .fa {
          transform: rotate(180deg);
        }
      }
    }

    .sidebar-collapse {
      right: 0;

      button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
