import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import { createPinia } from "pinia";
import InlineSvg from "vue-inline-svg";
const pinia = createPinia();

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(i18n);
app.component("inline-svg", InlineSvg);

router.isReady().then(() => app.mount("#app"));
