<script setup lang="ts">
import asset1 from "@/assets/images/test/asset1.svg";
import asset2 from "@/assets/images/test/asset2.svg";
import { useBreakpointsLogic } from "@/assets/js/globalComposables";

const props = defineProps(["sidebarID"]);
const { sidebarID } = props;
const { isMobile } = useBreakpointsLogic();

const isSidebarHidden = ref(false);
const onChangeSidebarWidth = (evt: string) => {
  isSidebarHidden.value = evt === "0px";
};
const onChangeHideSidebar = (evt: boolean) => {
  isSidebarHidden.value = evt;
};

let isMobileSidebarExpanded = ref(false);
watchEffect(() => {
  isMobileSidebarExpanded.value = !isMobile.value;
});

const assetList = [
  {
    name: "asset1",
    image: asset1,
  },
  {
    name: "asset2",
    image: asset2,
  },
];
</script>

<template lang="pug">
.left-sidebar(:class="{ hidden: isSidebarHidden || !isMobileSidebarExpanded }")
  sidebar-comp(
    :sidebarID="sidebarID",
    :is-sidebar-hidden="isSidebarHidden",
    :is-mobile-sidebar-expanded="isMobileSidebarExpanded",
    orientation="left",
    @change-sidebar-width="onChangeSidebarWidth",
    @hide-sidebar="onChangeHideSidebar"
  )
    template(#comp)
      left-sidebar-content(:list="assetList")
</template>

<style lang="scss" scoped>
.left-sidebar {
  flex: 1;
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: $background-color;
}
</style>
