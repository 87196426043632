import { defineStore } from "pinia";
import { useFavicon, useTitle } from "@vueuse/core";
const icon = useFavicon();
const title = useTitle();

interface elementPropItem {
  name: string;
  label: string;
  value: any;
  type?: string;
  placeholder?: string;
  inputType?: string;
  onInput?: any;
  onChange?: any;
  exportableLogic?: any;
}

const globalPageProps: elementPropItem[] = [
  {
    name: "page-height",
    label: "Page Height",
    value: "2000px",
    exportableLogic(itemObj: any) {
      // Create CSS
      // height: %value%;
      // min-height: %value%;
    },
  },
  {
    name: "font-url",
    label: "Font-URL",
    value: "",
  },
  {
    name: "font-family",
    label: "Font Family",
    value: "Arial",
    exportableLogic(itemObj: any) {
      // Create CSS
      // font-family: '%value%', 'Arial';
    },
  },
  {
    name: "font-size",
    label: "Font Size",
    value: "12px",
    exportableLogic(itemObj: any) {
      // Create CSS
      // font-size: '%value%';
    },
  },
  {
    name: "padding",
    label: "Padding",
    value: "0",
    exportableLogic(itemObj: any) {
      // Create CSS
      // padding: %value%;
    },
  },
  {
    name: "margin",
    label: "Margin",
    value: "0",
    exportableLogic(itemObj: any) {
      // Create CSS
      // padding: %value%;
    },
  },
  {
    name: "favicon",
    label: "Favicon",
    value: "",
    onChange(evt: Event, value: string) {
      // Add relative? path for a favicon (from assets)
      icon.value = value;
    },
    exportableLogic(itemObj: any) {
      // Update index.html
      // <link rel="icon" href="/favicon.ico" />
    },
  },
  {
    name: "site-title",
    label: "Site title",
    value: "Mars HTML Builder",
    onInput(evt: Event, value: string) {
      title.value = value;
    },
    exportableLogic(itemObj: any) {
      // Update index.html
      // <title>%value%</title>
    },
  },
  {
    name: "site-description",
    label: "Site description",
    value: "",
    type: "textarea",
    exportableLogic(itemObj: any) {
      // Update index.html
      // <meta
      //   name="description"
      //   content="%value%"
      // />
    },
  },
];

export const useDefaultStore = defineStore("main", {
  state: () => ({
    globalPageProps,
  }),
  getters: {
    getGlobalProp: (state) => (key: string) => {
      return state.globalPageProps.find((item) => item.name === key);
    },
  },
  actions: {
    updateGlobalItemValue(dataArray: any) {
      for (const oneRow of dataArray) {
        const obj = this.globalPageProps.find(
          (item) => item.name === oneRow.key
        );
        if (obj) {
          obj.value = oneRow.value;
        }
      }
    },
    exportGlobalItems() {
      // This is only invoked at the "Save | Finish" phase
      for (const item of this.globalPageProps) {
        item.exportableLogic?.(item);
      }
    },
  },
});
