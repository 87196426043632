<script setup lang="ts">
// TODO Use dynamic store props instead of hardcoded values

import { useDefaultStore } from "@/store";

const store = useDefaultStore();
const globalProps = store.globalPageProps;

const isElementSelected = ref(false);
</script>

<template lang="pug">
.right-sidebar-content
  h3.title Properties
  template(v-if="!isElementSelected")
    hr
    .super-group
      .title Global Props
      template(v-for="gProp in globalProps", :key="gProp.name")
        .input-group
          span {{ gProp.label }}
          input(
            :type="gProp.inputType ? gProp.inputType : 'text'",
            v-model="gProp.value",
            :placeholder="gProp.placeholder || gProp.label",
            v-if="gProp.type !== 'textarea'",
            @input="gProp.onInput?.($event, gProp.value)",
            @change="gProp.onChange?.($event, gProp.value)"
          )
          textarea(
            :value="gProp.value",
            :placeholder="gProp.placeholder || gProp.label",
            v-if="gProp.type === 'textarea'",
            @input="gProp.onInput?.($event, gProp.value)",
            @change="gProp.onChange?.($event, gProp.value)"
          )
  template(v-if="isElementSelected")
    hr
    .super-group
      .title Element Props
      .input-group
        span Font Family
        input(type="text", value="Roboto")
      .input-group
        span Font Size
        input(type="text", value="15px")
      .input-group
        span Font Height
        input(type="text", value="500")
      .input-group
        span Line Height
        input(type="text", value="20px")
      label.checkbox-wrap
        input(type="checkbox")
        span A (Link) Tag Wrap
      .input-group
        span Link Source
        input(type="text", value="https://enonsolutions.com/")
      label.checkbox-wrap
        input(type="checkbox", :checked="true")
        span Link - Open in a new tab
</template>

<style lang="scss" scoped>
.right-sidebar-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;

  hr {
    border: none;
    border-top: 1px solid $border-color1;
  }

  .super-group {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: $text-color;

    > .title {
      font-weight: 600;
      font-size: 13px;
    }
  }

  .checkbox-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    accent-color: $app-accent-color1;
  }
}
</style>
