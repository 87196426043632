<script setup lang="ts">
import { useBreakpointsLogic } from "@/assets/js/globalComposables";

const props = defineProps(["sidebarID"]);
const { sidebarID } = props;
const { isMobile } = useBreakpointsLogic();

const isSidebarHidden = ref(false);
const onChangeSidebarWidth = (evt: string) => {
  isSidebarHidden.value = evt === "0px";
};
const onChangeHideSidebar = (evt: boolean) => {
  isSidebarHidden.value = evt;
};

let isMobileSidebarExpanded = ref(false);
watchEffect(() => {
  isMobileSidebarExpanded.value = !isMobile.value;
});
</script>

<template lang="pug">
.right-sidebar(:class="{ hidden: isSidebarHidden || !isMobileSidebarExpanded }")
  sidebar-comp(
    :sidebarID="sidebarID",
    :is-sidebar-hidden="isSidebarHidden",
    :is-mobile-sidebar-expanded="isMobileSidebarExpanded",
    orientation="right",
    @change-sidebar-width="onChangeSidebarWidth",
    @hide-sidebar="onChangeHideSidebar"
  )
    template(#comp)
      right-sidebar-content
</template>

<style lang="scss" scoped>
.right-sidebar {
  flex: 1;
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: $background-color;
}
</style>
