<script setup lang="ts">
import pcImage from "@/assets/images/pc.svg";
import mobileImage from "@/assets/images/mobile.svg";

const canvasTabs = reactive([
  {
    name: "desktop",
    label: "Desktop",
    width: "1200px",
    image: pcImage,
  },
  {
    name: "mobile",
    label: "Mobile",
    width: "768px",
    image: mobileImage,
  },
]);

let activeTabId = ref("");
const canvasActiveObject = computed(() => {
  if (activeTabId.value) {
    return (
      canvasTabs.find((el) => el.name === activeTabId.value.toLowerCase()) || {}
    );
  }
  return canvasTabs[0];
});

const onClickTab = (tab: any) => {
  activeTabId.value = tab.name;
  onStoreDataToStorage();
};

const onStoreDataToStorage = () => {
  localStorage.htmlBuilderTab = JSON.stringify(canvasActiveObject.value);
};

const onStorePageToStorage = (pageData: object) => {
  localStorage.htmlBuilderPage = JSON.stringify(pageData);
};

onMounted(() => {
  const loadTabData = () => {
    if (localStorage.htmlBuilderTab) {
      try {
        activeTabId.value = JSON.parse(localStorage.htmlBuilderTab)?.name;
      } catch (err) {
        // ignored
      }
    }
  };
  const loadPageData = () => {
    if (localStorage.htmlBuilderTab) {
      try {
        activeTabId.value = JSON.parse(localStorage.htmlBuilderTab)?.name;
      } catch (err) {
        // ignored
      }
    }
  };

  loadTabData();
  loadPageData();
});

// Unused sidebar block
const isLeftSidebarHidden = ref(false); // Unused yet
const isRightSidebarHidden = ref(false); // Unused yet
// const onShowLeftSidebar = (evt: boolean) => {
//   isLeftSidebarHidden.value = evt;
// };

// const onShowRightSidebar = (evt: boolean) => {
//   isRightSidebarHidden.value = evt;
// };
</script>

<template lang="pug">
#home-page
  .top-section
    top-bar(
      :canvas-tabs="canvasTabs",
      :canvas-active="canvasActiveObject",
      @tab-click="onClickTab"
    )
  .content-section
    .center-canvas
      web-canvas(
        :canvas-active="canvasActiveObject",
        @on-store-canvas-page="onStorePageToStorage"
      )
    .left-sidebar-fixed
      left-sidebar(
        sidebarID="left-sidebar",
        :is-sidebar-hidden="isLeftSidebarHidden"
      )
    .right-sidebar-fixed
      right-sidebar(
        sidebarID="right-sidebar",
        :is-sidebar-hidden="isRightSidebarHidden"
      )
</template>

<style lang="scss" scoped>
#home-page {
  $top-header-height: $nav-top-bar;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  flex-direction: column;

  .top-section {
    display: flex;
    width: 100%;
    height: $top-header-height;
  }

  .content-section {
    display: flex;
    flex: 1;
    justify-content: space-between;
    position: fixed;
    inset: 0;
    top: $top-header-height;
    width: 100%;

    .left-sidebar-fixed,
    .right-sidebar-fixed {
      display: flex;
    }

    .center-canvas {
      position: fixed;
      inset: 0;
      top: $top-header-height;
      display: flex;
      justify-content: center;
      flex: 1;
      overflow: hidden;
    }
  }
}
</style>
