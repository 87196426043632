<script setup lang="ts">
const props = defineProps(["canvasTabs", "canvasActive"]);
const emit = defineEmits(["tab-click"]);

const pageTitle = "Mars HTML Builder";

const onClickTab = (evt: MouseEvent, tab: any) => {
  emit("tab-click", tab);
};
</script>

<template lang="pug">
.top-bar
  .left-side
    .logo
      img(src="@/assets/images/logo.svg")
    .title
      h2(:title="pageTitle") {{ pageTitle }}
  .center
  .right-side
    .tabs
      template(v-for="(tab, index) in canvasTabs", :key="tab.label")
        .tab(
          :class="{ active: props.canvasActive.name === tab.name }",
          :title="tab.label",
          @click="onClickTab($event, tab)"
        )
          inline-svg(:src="tab.image", :alt="tab.label")
        .line(v-if="index !== canvasTabs.length - 1")
</template>

<style lang="scss" scoped>
.top-bar {
  $logo-size: 33px;

  display: flex;
  background-color: $background-color;
  width: 100%;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.19);
  z-index: 1;
  user-select: none;

  > * {
    flex: 1;
    display: flex;
  }

  .left-side {
    overflow: hidden;

    .logo {
      width: $logo-size;
      height: $logo-size;
      align-self: center;
      margin: 0 21px;
      pointer-events: none;
    }

    .title {
      align-self: center;
      overflow: hidden;

      h2 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .right-side {
    .tabs {
      display: flex;
      justify-content: flex-end;
      align-self: center;
      gap: 10px;
      width: 100%;
      padding: 10px 50px 10px 10px;

      .tab {
        $accent-color: $app-accent-color1;
        $size: 37px;
        display: flex;
        padding: 10px;
        width: $size;
        height: $size;
        justify-content: center;
        border-radius: 20%;
        align-items: center;
        transition: all 0.3s ease;
        cursor: pointer;

        &.active {
          background-color: $button-background-color1;
          border-radius: 50%;

          :deep() svg {
            path {
              fill: $accent-color;
            }
          }
        }

        &:hover {
          background-color: $button-background-color1;
          border-radius: 40%;

          :deep() svg {
            path {
              fill: lighten($color: $accent-color, $amount: 10);
            }
          }
        }
      }

      .line {
        height: 18px;
        width: 1px;
        background-color: $border-color1;
        align-self: center;
      }
    }
  }
}
</style>
