<template lang="pug">
.drag-wrap(:style="[{ width: barCompWidth }, { height: barCompHeight }]")
  .overlay(v-if="isResizeDrag", @mouseup.prevent="isResizeDrag = false")
  .drag-bar(
    :title="title",
    :class="[{ active: isResizeDrag, vertical: isVertical, 'prevent-resize': disabled }]",
    @mousedown.prevent="onMoveDragBar($event, 1)",
    @mouseup.prevent="isResizeDrag = false",
    @touchstart.prevent="onMoveDragBar($event, 2)",
    @touchend.prevent="isResizeDrag = false",
    @touchcancel.prevent="isResizeDrag = false"
  )
</template>

<script>
export default {
  props: {
    isVertical: {
      type: Boolean,
      default: false,
      required: false,
    },
    barWidth: {
      type: String,
      default: "",
    },
    barHeight: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    mouseMovePropHandler: {
      type: Function,
      default: () => {
        console.warn("Missing mouse move func");
        return null;
      },
    },
    removeMouseMovePropHandler: {
      type: Function,
      default: () => {
        // Optional
        return null;
      },
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isResizeDrag: false,
    };
  },
  computed: {
    mouseResizeStyle() {
      if (this.isVertical) {
        return "n-resize";
      }
      return "e-resize";
    },
    barCompWidth() {
      if (!this.barWidth) {
        if (this.isVertical) {
          return "100%";
        } else if (this.disabled) {
          return "1px";
        }
        return "4px";
      }
      return this.barWidth;
    },
    barCompHeight() {
      if (!this.barHeight) {
        if (!this.isVertical) {
          return "100%";
        } else if (this.disabled) {
          return "1px";
        }
        return "4px";
      }
      return this.barHeight;
    },
  },
  watch: {
    isResizeDrag(isResize) {
      if (!isResize) {
        this.removeEventListeners();
      }
    },
  },
  methods: {
    onMoveDragBar(evt, eventType) {
      if (evt.detail === 2) {
        // Double click event
        this.$emit("dblclick", evt);
        return;
      }

      const isMouse = eventType === 1;
      const isLeftClick = evt.button === 0;

      if (this.disabled || (isMouse && !isLeftClick)) {
        this.removeEventListeners();
        return;
      }

      this.isResizeDrag = true;
      document.body.style.cursor = this.mouseResizeStyle;
      document.addEventListener("mousemove", this.handleMouseMove);
      document.addEventListener("touchmove", this.handleMouseMove);
    },
    handleMouseMove(evt) {
      document.addEventListener("mouseup", this.removeHandleMouseMove);
      document.addEventListener("touchend", this.removeHandleMouseMove);
      document.addEventListener("touchcancel", this.removeHandleMouseMove);
      if (this.isVertical) {
        this.mouseMovePropHandler(evt.clientY, evt);
      } else {
        this.mouseMovePropHandler(evt.clientX, evt);
      }
    },
    removeHandleMouseMove(evt) {
      document.removeEventListener("mouseup", this.removeHandleMouseMove);
      document.removeEventListener("touchend", this.removeHandleMouseMove);
      document.removeEventListener("touchcancel", this.removeHandleMouseMove);
      this.isResizeDrag = false;
      this.removeMouseMovePropHandler(evt);
    },
    removeEventListeners() {
      document.body.style.cursor = "unset";
      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("touchmove", this.removeHandleMouseMove);
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-wrap {
  $light-border: #d3d3d321;

  .drag-bar {
    background: darken($color: $light-border, $amount: 20);
    height: 100%;
    width: 100%;

    &:hover,
    &.active {
      z-index: 100;

      &:not(.prevent-resize) {
        cursor: e-resize;
        background: lighten($color: $light-border, $amount: 30);

        &.vertical {
          cursor: n-resize;
        }
      }
    }
  }

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 99;
  }
}
</style>
