<script setup lang="ts">
import { useDefaultStore } from "@/store";

const props = defineProps(["canvasActive"]);
const emit = defineEmits(["onStoreCanvasActive"]);
const store = useDefaultStore();

const pageProps = reactive({
  zoom: 0.7,
  translate: {
    x: 0,
    y: -200,
  },
});

const pagePropsComputed = computed(() => {
  return {
    ...pageProps,
    pageHeight: store.getGlobalProp("page-height")?.value,
    fontFamily: store.getGlobalProp("font-family")?.value,
    fontSize: store.getGlobalProp("font-size")?.value,
    margin: store.getGlobalProp("margin")?.value,
    padding: store.getGlobalProp("padding")?.value,
  };
});

const canvasStyle = computed(() => {
  return {
    transform: `translate(${pagePropsComputed.value.translate.x}px, ${pagePropsComputed.value.translate.y}px) scale(${pagePropsComputed.value.zoom})`,
    minHeight: `${pagePropsComputed.value.pageHeight}`,
    maxHeight: `${pagePropsComputed.value.pageHeight}`,
    fontFamily: `${pagePropsComputed.value.fontFamily}`,
    fontSize: `${pagePropsComputed.value.fontSize}`,
    margin: `${pagePropsComputed.value.margin}`,
    padding: `${pagePropsComputed.value.padding}`,
    width: props.canvasActive.width || "1200px", // 1200 - PC, 768 - Phone
  };
});

const onScrollEvent = (evt: WheelEvent) => {
  if (!evt.ctrlKey && !evt.shiftKey) return;

  // TODO Linux can't use CTRL + SHIFT together, investigate further
  evt.preventDefault();

  let increment = 0.03;
  if (evt.shiftKey) {
    increment = 0.01;
  }

  let zoomEl = pageProps.zoom;
  if (evt.deltaY < 0) {
    // Zoom in
    zoomEl += increment;
  } else {
    // Zoom out
    zoomEl -= increment;
  }

  // Parsing number because of precision number
  pageProps.zoom = parseFloat(zoomEl.toFixed(3));
  emit("onStoreCanvasActive");
};

const useMouseComposable = () => {
  const isDraggingCanvas = ref(false);
  interface mousePropsInterface {
    initial: {
      x: number;
      y: number;
    };
    start: {
      x: number;
      y: number;
    };
    end: {
      x: number;
      y: number;
    };
  }
  let mouseProps: mousePropsInterface = reactive({
    initial: {
      x: -1,
      y: -1,
    },
    start: {
      x: -1,
      y: -1,
    },
    end: {
      x: -1,
      y: -1,
    },
  });
  const onMouseDownEvent = (evt: MouseEvent) => {
    const isMiddleClick = evt.button === 1;
    const isOtherClickControl = evt.ctrlKey && !isMiddleClick;
    if (!isOtherClickControl && !isMiddleClick) return;

    isDraggingCanvas.value = true;
    mouseProps.initial = {
      x: pageProps.translate.x,
      y: pageProps.translate.y,
    };
    mouseProps.start = {
      x: evt.screenX,
      y: evt.screenY,
    };
  };
  const onMouseUpEvent = (evt: MouseEvent) => {
    // Reset values
    isDraggingCanvas.value = false;

    mouseProps = {
      initial: {
        x: -1,
        y: -1,
      },
      start: {
        x: -1,
        y: -1,
      },
      end: {
        x: -1,
        y: -1,
      },
    };
  };
  const onMouseMoveEvent = (evt: MouseEvent) => {
    if (mouseProps.start.x !== -1 && mouseProps.start.y !== -1) {
      mouseProps.end = {
        x: evt.screenX,
        y: evt.screenY,
      };

      const getDiff = (key: keyof typeof mouseProps.start) => {
        const diffVal = mouseProps.end[key] - mouseProps.start[key];

        return diffVal;
      };

      pageProps.translate.x = mouseProps.initial.x + getDiff("x");
      pageProps.translate.y = mouseProps.initial.y + getDiff("y");
      emit("onStoreCanvasActive");
    }
  };

  return {
    mouseProps,
    isDraggingCanvas,
    onMouseDownEvent,
    onMouseUpEvent,
    onMouseMoveEvent,
  };
};

const { isDraggingCanvas, onMouseDownEvent, onMouseUpEvent, onMouseMoveEvent } =
  useMouseComposable();

const onResetZoom = () => {
  pageProps.zoom = 1.0;
  emit("onStoreCanvasActive");
};
</script>

<template lang="pug">
.web-canvas(
  :class="{ 'cursor-grab': isDraggingCanvas }",
  @wheel="onScrollEvent",
  @mousedown="onMouseDownEvent",
  @mousemove="onMouseMoveEvent",
  @mouseup="onMouseUpEvent"
)
  .web-canvas-page(:style="canvasStyle")
  .page-dimensions
    span 1920 x 2000
  .scroll-size(@click="onResetZoom")
    span Zoom level:
    span {{ (pageProps.zoom * 100).toFixed(0) }} %
</template>

<style lang="scss" scoped>
.web-canvas {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transform: scale(1);
  background-color: $medium-gray;

  .web-canvas-page {
    display: flex;
    background-color: $text-color;

    .center {
      color: black;
      align-self: center;
      width: 100%;
      text-align: center;
    }
  }

  .page-dimensions {
    display: flex;
    font-size: 12px;
    gap: 10px;
    user-select: none;
    position: absolute;
    position: absolute;
    font-weight: 600;
    top: 6px;
    mix-blend-mode: exclusion;
  }

  .scroll-size {
    display: flex;
    font-size: 12px;
    gap: 10px;
    user-select: none;
    font-weight: 600;
    bottom: 10px;
    position: fixed;
    mix-blend-mode: exclusion;
    cursor: pointer;
  }

  &.cursor-grab {
    cursor: grab;
  }
}
</style>
