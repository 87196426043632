import { useEventListener, useBreakpoints } from "@vueuse/core";

export function useBreakpointsLogic() {
  const breakpoints = useBreakpoints({
    mobileL: 640,
    tablet: 768,
    laptop: 1024,
    desktop: 1280,
  });

  let isMobile = breakpoints.smaller("tablet");
  useEventListener("resize", () => {
    isMobile = breakpoints.smaller("tablet");
  });

  return { isMobile, breakpoints };
}
