<template lang="pug">
.browser-detect-wrap(:style="wrapperStyle")
  transition(name="scale")
    .content(v-if="isLoadDelay")
      .title You're using an outdated browser, please download newest version of Google Chrome or Mozilla Firefox
      .action
        a.chrome(href="https://www.google.com/chrome/", target="_blank")
          img(src="./chrome.svg")
        a.firefox(
          href="https://www.mozilla.org/en-US/firefox/new/",
          target="_blank"
        )
          img(src="./firefox.svg")
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoadDelay: false,
    };
  },
  computed: {
    wrapperStyle() {
      return this.options.wrapper?.style;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoadDelay = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.browser-detect-wrap {
  $dark-gray: $background-color;
  $button-border-radius: $button-border-radius;

  display: flex;
  background: rgba(0, 0, 0, 0.7); // Fallback
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  color: white;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: $dark-gray;
    padding: 20px;
    border-radius: $button-border-radius;
    cursor: default;
    user-select: none;

    .title {
      font-weight: bold;
    }

    .action {
      display: flex;
      gap: 20px;

      img {
        width: 80px;
        height: 80px;
        cursor: pointer;
      }
    }
  }

  .scale-enter-active,
  .scale-leave-active {
    transition: transform 0.2s ease;
  }

  .scale-enter,
  .scale-enter-from,
  .scale-leave-to {
    transform: scale(0.9);
    opacity: 0;
  }
}
</style>
